import './App.css';
import Routers from './routes';

function App() {
  return (
    <div className='App'>
      <Routers />
    </div>
  )
}

export default App;